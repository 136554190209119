<template>
  <div>
    <title>SIM INVENTORY ~ PRODUCT LIST DATA BOM PROCESS DETAILS</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Product <b style="color:red">{{ product }} </b> => Part
        <b style="color:red">{{ part }} </b> => Process
        <b style="color:red">{{ process }}</b>
        <br />
        <h4>
          The following is a list of master data lists for detailed BOM process
          product lists
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div v-if="addData" class="col-md-5">
          <!--<router-link to="/add-produkbom-proses">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Add Process BOM Detail Product Data </i>
            </button>
          </router-link>-->
          <div class="box box-primary">
            <!-- <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Product Data Process Details BOM</h3>
            </div>
        
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Item Code</label>
                    <input
                      type="text"
                      v-model="isidata.kodebarang"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Item Code"
                    />
                    <input
                      type="hidden"
                      v-model="isidata.idxprodukprosesd"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Item Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getbarang()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.namabarang"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <input
                    type="text"
                    v-model="isidata.unit"
                    readonly
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="text"
                    v-model="isidata.qty"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Please Enter Qty"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Trash Percent</label>
                  <input
                    type="text"
                    v-model="isidata.trash"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Please Enter Trash Percent"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
              </div>
            </form> -->
            <div class="box-header">
              <i class="fa fa-list"></i>
              <h3 class="box-title">
                Add Material of <b style="color:red">{{ process }}</b>
              </h3>
              <input
                type="hidden"
                v-model="isidata.idxprodukprosesd"
                autocomplete="off"
                class="form-control"
                placeholder="Item Code"
              />
            </div>

            <div class="box-body">
              <div class="form-group">
                <label for="exampleInputEmail1">Item Type</label>
                <select2
                  :data="barangtipe"
                  :value="valuetype"
                  @update="updatetype($event)"
                ></select2>
                <!--<select
                  @change="veiwMaterial($event)"
                  class="form-control"
                  v-model="tipebarang"
                  id="tipebarang"
                >
                  <option disabled>--Please Select--</option>
                  <option
                    v-for="tipebrg in barangtipe"
                    :key="tipebrg.tipe"
                    :value="tipebrg.tipe"
                  >
                    {{ tipebrg.tipe }}
                  </option>
                </select> -->
                <input type="hidden" v-model="isidata.tipe" id="tipe" name="" />
              </div>
            </div>

            <div class="box-body">
              <div class="row">
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by Item Code"
                    class="form-control"
                    v-model="namesearch2"
                  />
                </div>
                <div class="col-xs-1">
                  <button
                    type="button"
                    @click="searchdata2()"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <br />
              <p></p>
              <div class="table-responsive">
                <table
                  width="100%"
                  id="mydata"
                  class="table table-bordered table-striped"
                >
                  <thead>
                    <tr v-if="isPart">
                      <th width="5%">#</th>
                      <th width="20%">Part Number</th>
                      <th width="25%">Part Name</th>
                      <th width="15%">Qty</th>
                      <th width="8%">Trash Percent</th>
                    </tr>
                    <tr v-else>
                      <th width="5%">#</th>
                      <th width="20%">Item Code</th>
                      <th width="25%">Item Name</th>
                      <th width="15%">Qty</th>
                      <th width="8%">Unit</th>
                      <th width="8%">Trash Percent</th>
                    </tr>
                  </thead>
                  <tbody v-if="isPart">
                    <tr v-for="classdata in list_paged2" :key="classdata.id">
                      <td>
                        <button
                          @click="saveData2(classdata)"
                          type="button"
                          class="btn btn-primary"
                        >
                          <i class="fa fa-save"></i>
                        </button>
                      </td>
                      <td>
                        {{ classdata.part_no }}
                      </td>
                      <td>
                        {{ classdata.part_name }}
                      </td>
                      <td>
                        <input
                          :id="'inp-' + classdata.id"
                          type="text"
                          class="form-control"
                          v-model="classdata.jumlah"
                          :name="'nm-' + classdata.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inptp-' + classdata.id"
                          type="text"
                          class="form-control"
                          v-model="classdata.trash"
                          :name="'nmtp-' + classdata.id"
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="classdata in list_paged2" :key="classdata.id">
                      <td>
                        <button
                          @click="saveData2(classdata)"
                          type="button"
                          class="btn btn-primary"
                        >
                          <i class="fa fa-save"></i>
                        </button>
                      </td>
                      <td>
                        {{ classdata.kode_barang }}
                      </td>
                      <td>
                        {{ classdata.nama_barang }}
                      </td>
                      <td>
                        <input
                          :id="'inp-' + classdata.id"
                          type="text"
                          class="form-control"
                          v-model="classdata.jumlah"
                          :name="'nm-' + classdata.id"
                        />
                      </td>
                      <td>
                        {{ classdata.unit }}
                      </td>
                      <td>
                        <input
                          :id="'inptp-' + classdata.id"
                          type="text"
                          class="form-control"
                          v-model="classdata.trash"
                          :name="'nmtp-' + classdata.id"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="box-footer">
              <button
                data-toggle="modal"
                data-target="#additem"
                type="button"
                class="btn btn-warning btn-xs"
              >
                <i class="fa fa-plus"> Add Material</i>
              </button>
              <p>(*) If material not listed</p>
            </div>
            <div
              class="modal fade"
              id="additem"
              tabindex="-1"
              role="dialog"
              aria-labelledby="additemTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-sm" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Save Master Item Data</h5>
                  </div>
                  <div class="modal-body">
                    <form role="form">
                      <div class="box-body">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Item code</label>
                          <input
                            type="text"
                            v-model="isidata.kodebarang"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Item code"
                          />
                        </div>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Item Name</label>
                          <input
                            type="text"
                            v-model="isidata.namabarang"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Item name"
                          />
                        </div>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Unit</label>
                          <select
                            class="form-control"
                            v-model="isidata.unit"
                            id="unit"
                          >
                            <option disabled>--Please Select--</option>
                            <option
                              v-for="unitdata in unit"
                              :key="unitdata.id"
                              :value="unitdata.unit"
                            >
                              {{ unitdata.unit }}
                            </option>
                          </select>
                          <!-- <input
                    type="text"
                    v-model="isidata.unit"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Unit"
                  /> -->
                        </div>
                        <div class="form-group">
                          <label for="exampleInputEmail1">Type</label>
                          <select
                            class="form-control"
                            v-model="isidata.tipebarang"
                            id="proses"
                          >
                            <option disabled>--Please Select--</option>
                            <option
                              v-for="brgtipe in tipebrg"
                              :key="brgtipe.id"
                              :value="brgtipe.tipe"
                            >
                              {{ brgtipe.tipe }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      @click="saveDataMaterial()"
                      class="btn btn-primary"
                      data-dismiss="modal"
                    >
                      Save changes
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isFrmeditData" class="col-md-5">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Product Data Process Details BOM</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Item Code</label>
                    <input
                      type="text"
                      v-model="isidata.kodebarang"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Item Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getbarang()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.namabarang"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <input
                    type="text"
                    v-model="isidata.unit"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="text"
                    v-model="isidata.qty"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Please Enter Qty"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Trash Percent</label>
                  <input
                    type="text"
                    v-model="isidata.trash"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Please Enter Trash Percent"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <button @click="awaldepan()" class="btn btn-success">
                  Kembali
                </button>
                <!-- <router-link to="/produk">
                  <button class="btn btn-success">Kembali</button>
                </router-link> -->
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-7">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">
                  List Material / Part of
                  <b style="color:red"> {{ process }} </b> Process
                </h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-5">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Item Name"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Qty</th>
                      <th>Trash Percent</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.nama_barang }}</td>
                      <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                      <td>{{ classdata.persen_sampah }}</td>
                      <td>
                        <!-- <button
                          @click="EditData(classdata.id)"
                          title="Edit Process BOM Material Details Product"
                          class="btn btn-info"
                        >
                          <i class="fa fa-edit"></i></button
                        >&nbsp; -->
                        <!-- <router-link
                          :to="
                            '/list-prosesprodukbommaterial-detail/' +
                              classdata.id
                          "
                        >
                          <button
                            title="List Process BOM Material Details Product"
                            class="btn btn-success"
                          >
                            <i class="fa fa-list"></i>
                          </button> </router-link
                        >&nbsp; -->
                        <button
                          title="Delete Process BOM Details Product"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Qty</th>
                      <th>Trash Percent</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="6" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination
  },
  data() {
    return {
      isPart: false,
      isidata: {
        tipe: "",
        kodebarang: "",
        namabarang: "",
        idxprodukprosesd: "",
        unit: "",
        tipebarang: "",
        qty: "",
        trash: ""
      },
      classdata: {
        id: "",
        kode_barang: "",
        nama_barang: "",
        jumlah: "",
        unit: "",
        trash: ""
      },
      product: "",
      part: "",
      process: "",
      addData: true,
      isFrmeditData: false,
      isExist: true,
      loading: false,
      namesearch: "",
      namesearch2: "",
      valuetype: "",
      list_paged: [],
      list_paged2: [],
      barangtipe: [],
      tipebrg: [],
      unit: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSize2: 10,
      pageSizes: [10, 50, 100, 500],
      pageSizes2: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
    this.getbarangtipe();
    this.getunit();
    this.getkategoribarang();
    sessionStorage.setItem("idprodprosbom", this.$route.params.id);
    this.isidata.idxprodukprosesd = this.$route.params.id;
    this.product = localStorage.bomproduct;
    this.part = localStorage.bompart;
    this.process = localStorage.bomprocess;
  },
  methods: {
    awaldepan() {
      this.addData = true;
      this.isFrmeditData = false;
      this.isidata.kodebarang = "";
      this.isidata.namabarang = "";
      this.isidata.idxprodukprosesd = "";
      this.isidata.unit = "";
      this.isidata.qty = "";
      this.isidata.trash = "";
      this.fetchData();
    },

    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if(this.namesearch == '') {
        this.fetchData();
      } else {
        this.searchdata()
      }
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if(this.namesearch == '') {
        this.fetchData();
      } else {
        this.searchdata()
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    getkategoribarang() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getsupplierbykode?kode_supplier=" + this.isidata.kodesupplier;
      const urlAPIget =
        this.$apiurl + "kategori_barang/getkategori_barang_list_tipe";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          this.tipebrg = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getunit() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "unit/getalldataunit?length=2000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          this.unit = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveData2: function(classdata) {
      this.loading = true;
      var kdbrg;
      if (this.isPart == true) {
        kdbrg = classdata.part_no;
      } else {
        kdbrg = classdata.kode_barang;
      }
      var jml = Number(classdata.jumlah);
      var sat = classdata.unit;
      var samp = classdata.trash;
      console.log(jml + "nin");

      if (kdbrg == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (isNaN(jml) || jml < 1) {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (sat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (samp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Trash Percent can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        var kodeuser = sessionStorage.getItem("kodeuser");
        /* var paramdata = {
          nomor_produk: this.produkno,
          kode_barang: classdata.kode_barang,
          qty: classdata.jumlah,
          notes: "",
          unit: classdata.unit,
          kode_user: kodeuser
        }; */
        var paramdata = {
          id_produk_d_proses: this.isidata.idxprodukprosesd,
          kode_barang: kdbrg,
          unit: classdata.unit,
          kode_user: kodeuser,
          qty: classdata.jumlah,
          persen_sampah: classdata.trash,
          tipe: this.isidata.tipe
        };

        console.log(paramdata);
        var tokenlogin = sessionStorage.getItem("token");
        var urlAPIsavedata =
          this.$apiurl + "produk_d_proses_bom/saveproduk_d_proses_bom";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    veiwMaterial(event) {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const brgtype = event.target.value;
      let uri;
      if (brgtype == "PART") {
        uri =
          "produk_d/getalldataproduk_d?page=1&length=1000&nomor_produk=" +
          sessionStorage.getItem("noprd");
        this.isPart = true;
        this.isidata.tipe = "part";
      } else {
        uri =
          "barang/getalldatabarang_by_tipe?length=1000&page=1&tipe=" + brgtype;
        this.isPart = false;
        this.isidata.tipe = "material";
      }
      const urlAPIget = this.$apiurl + uri;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          console.log(resp);
          this.list_paged2 = resp.data.data;
          // this.count = resp.data.datatotalcount;
          //   console.log(this.count);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    saveDataMaterial() {
      this.loading = true;
      var kode = this.isidata.kodebarang;
      var nama = this.isidata.namabarang;
      var unit = this.isidata.unit;
      var tipebrg = this.isidata.tipebarang;

      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (unit == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tipebrg == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item type can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_barang: this.isidata.kodebarang,
          nama_barang: this.isidata.namabarang,
          unit: this.isidata.unit,
          tipe: this.isidata.tipebarang,
          kode_user: kodeuser
        };
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/barang/savebarang";
        const urlAPIsavebrg = this.$apiurl + "barang/savebarang";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavebrg, paramdata, { headers })
          .then((resp) => {
            console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.kodebarang = "";
              this.isidata.namabarang = "";
              this.isidata.unit = "";
              this.isidata.tipebarang = "";
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    updatetype(value) {
      this.valuetype = value;
      const tokenlogin = sessionStorage.getItem("token");
      let uri;
      if (this.valuetype == "PART") {
        uri =
          "produk_d/getalldataproduk_d?page=1&length=1000&nomor_produk=" +
          sessionStorage.getItem("noprd");
        this.isPart = true;
        this.isidata.tipe = "part";
      } else {
        uri =
          "barang/getalldatabarang_by_tipe?length=1000&page=1&tipe=" + this.valuetype;
        this.isPart = false;
        this.isidata.tipe = "material";
      }
      const urlAPIget = this.$apiurl + uri;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          console.log(resp);
          this.list_paged2 = resp.data.data;
          // this.count = resp.data.datatotalcount;
          //   console.log(this.count);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    getbarangtipe() {
      this.loading = true;
      const urlAPIget =
        this.$apiurl + "kategori_barang/getkategori_barang_list_tipe";
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.barangtipe = resp.data.data;
          this.barangtipe.forEach((item) => {
            item.value = item.tipe;
            item.label = item.tipe;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async getbarang() {
      this.loading = true;
      const urlAPIget =
        this.$apiurl +
        "barang/getbarangbykode_barang?kode_barang=" +
        this.isidata.kodebarang;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Item Code not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.kodebarang = resp.data.data.kode_barang;
            this.isidata.namabarang = resp.data.data.nama_barang;
            this.isidata.unit = resp.data.data.unit;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async EditData(id) {
      this.isFrmeditData = true;
      this.addData = false;
      this.loading = true;
      const urlAPIget =
        this.$apiurl +
        "produk_d_proses_bom/getproduk_d_proses_bombyid?id=" +
        id;

      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.isidata.idxprodukprosesd = resp.data.data.id;
          this.isidata.kodebarang = resp.data.data.kode_barang;
          this.isidata.namabarang = resp.data.data.nama_barang;
          this.isidata.unit = resp.data.data.unit;
          this.isidata.qty = resp.data.data.qty;
          this.isidata.trash = resp.data.data.persen_sampah;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async saveData() {
      this.loading = true;
      var kodeb = this.isidata.kodebarang;
      var namab = this.isidata.namabarang;
      var units = this.isidata.unit;
      var jml = this.isidata.qty;
      var trash = this.isidata.trash;

      if (kodeb == "" || namab == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item Code / Item Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (units == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Units can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jml == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (trash == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Trash Percent can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        var kodeuser = sessionStorage.getItem("kodeuser");
        var paramdata = {
          id_produk_d_proses: this.isidata.idxprodukprosesd,
          kode_barang: this.isidata.kodebarang,
          unit: this.isidata.unit,
          kode_user: kodeuser,
          qty: this.isidata.qty,
          persen_sampah: this.isidata.trash
        };

        console.log(paramdata);
        var tokenlogin = sessionStorage.getItem("token");
        // var urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/saveproduk_d_proses";
        var urlAPIsavedata =
          // this.$apiurl + "produk_d_bom_proses/saveproduk_d_bom_proses";
          this.$apiurl + "produk_d_proses_bom/saveproduk_d_proses_bom";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.kodebarang = "";
              this.isidata.namabarang = "";
              this.isidata.unit = "";
              this.isidata.qty = "";
              this.isidata.trash = "";
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async updateData() {
      this.loading = true;
      var kodeb = this.isidata.kodebarang;
      var namab = this.isidata.namabarang;
      var units = this.isidata.unit;
      var jml = this.isidata.qty;
      var sampah = this.isidata.trash;
      var idxproddp = this.isidata.idxprodukprosesd;

      if (kodeb == "" || namab == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item Code / Item Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (units == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Units can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jml == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (sampah == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Trash Percent can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          id_produk_d_proses: this.isidata.idxprodukprosesd,
          kode_barang: this.isidata.kodebarang,
          unit: this.isidata.unit,
          kode_user: kodeuser,
          qty: this.isidata.qty,
          persen_sampah: this.isidata.trash
        };
        console.log(paramdata);
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/saveproduk_d_proses";
        const urlAPIUpdateData =
          this.$apiurl +
          "produk_d_proses_bom/updateproduk_d_proses_bom/" +
          idxproddp;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdateData, paramdata, { headers: headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
              this.addData = true;
              this.isidata.kodebarang = "";
              this.isidata.namabarang = "";
              this.isidata.unit = "";
              this.isidata.qty = "";
              this.isidata.trash = "";
              this.isFrmeditData = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/getalldataproduk_d_proses?length="+this.pageSize+"&page="+this.page+"&id_produk_d=" + this.$route.params.id
      const urlApiGETdetail =
        this.$apiurl +
        "produk_d_proses_bom/getalldataproduk_d_proses_bom?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&id_produk_d_proses=" +
        this.$route.params.id;
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    searchdata() {
      this.loading = true;
      const paramsst = this.getRequestParams(this.page);
      //const paramsst = this.getRequestParams(this.pagestat);
      const tokenlogin = sessionStorage.getItem("token");
        const urlApiGETdetail =
          this.$apiurl +
          "produk_d_proses_bom/getalldataproduk_d_proses_bom?length=" +
          this.pageSize +
          "&cari=" +
          this.namesearch +
          "&page=" +
          this.page +
          "&id_produk_d_proses=" +
          this.$route.params.id;
        axios
          .get(urlApiGETdetail, {
            paramsst,
            headers: { Authorization: tokenlogin }
          })
          .then((resp) => {
            if (resp.data.data.length == 0) {
              this.isExist = false;
            } else {
              this.isExist = true;
              this.list_paged = resp.data.data;
              this.count = resp.data.datatotalcount;
            }
            this.loading = false;
          })
          .catch((err) => {
            // console.log(err.response);
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data
            });
          });
    },
    searchdata2() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      const paramsst = this.getRequestParams(this.pagestat);
      const tokenlogin = sessionStorage.getItem("token");
      const urlApiGETdetail =
        this.$apiurl +
        "barang/getalldatabarang_by_tipe?length=" +
        this.pageSize2 +
        "&cari=" +
        this.namesearch2 +
        "&tipe=" +
        // document.getElementById("tipebarang").value;
        this.valuetype;
      console.log(urlApiGETdetail);
      axios
        .get(urlApiGETdetail, {
          paramsst,
          headers: { Authorization: tokenlogin }
        })
        .then((resp) => {

          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged2 = resp.data.data;
            this.count = resp.data.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          // console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel =
            this.$apiurl + "produk_d_proses_bom/hapusproduk_d_proses_bom/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              });
            });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
